:root {
  --blue-light: #a1f8eb;
  --blue-regular: #60e2cf;
  --blue-dark: #24aac4;
  --orange-light: #f9cec2;
  --orange-regular: #f5b499;
  --orange-dark: #f47f6a;
  --purple-light: #d985ff;
  --purple-regular: #bf75e0;
  --purple-dark: #8b55a3;
  --green-light: #81c784;
  --green-regular: #4caf50;
  --green-dark: #2e7d32;
}

.link {
  text-decoration: none;
}

.device-wrapper {
  cursor: pointer;
}

.web_app {
  max-width: 90% !important;
  margin: auto !important;
}

.mobile_app {
  max-width: 45% !important;
  margin: auto !important;
}

.emerging_app {
  max-width: 75% !important;
  margin: auto !important;
}

@media only screen and (max-width: 600px) {
  .mobile_app {
    max-width: 25% !important;
  }

  .emerging_app {
    max-width: 60% !important;
    margin: auto !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 769px) {
  .web_app {
    max-width: 75% !important;
    margin: auto !important;
  }

  .mobile_app {
    max-width: 20% !important;
  }

  .emerging_app {
    max-width: 50% !important;
    margin: auto !important;
  }
}

.web-app-container #fp-nav ul li a span {
  background: var(--green-regular);
}

.web-app-container #fp-nav ul li a.active span {
  background: var(--green-dark);
}

.emerging-app-container #fp-nav ul li a span {
  background: var(--purple-dark);
}

.emerging-app-container #fp-nav ul li a.active span {
  background: var(--purple-dark);
}

.mobile-app-container #fp-nav ul li a span {
  background: var(--orange-regular);
}

.mobile-app-container #fp-nav ul li a.active span {
  background: var(--orange-dark);
}

/* .footer #fp-nav ul li a span {
    background: var(--blue-regular);
}

.footer #fp-nav ul li a.active span {
    background: var(--blue-dark);
} */

.web-app-container .active .thumbnail_wrapper {
  border: 2px solid var(--green-dark) !important;
  cursor: default;
}

.mobile-app-container .active .thumbnail_wrapper {
  border: 2px solid var(--orange-dark) !important;
  cursor: default;
}

.emerging-app-container .active .thumbnail_wrapper {
  border: 2px solid var(--purple-dark) !important;
  cursor: default;
}

.footer .active .thumbnail_wrapper {
  border: 2px solid var(--blue-dark) !important;
  cursor: default;
}
